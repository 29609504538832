import * as actionTypes from './actionTypes';

export const loginGoogleStart = () => ({
  type: actionTypes.LOGIN_GOOGLE_START,
});

export const loginGoogleSuccess = () => ({
  type: actionTypes.LOGIN_GOOGLE_SUCCESS,
});

export const loginGoogleFail = (loginGoogleError: string) => ({
  type: actionTypes.LOGIN_GOOGLE_FAIL,
  loginGoogleError,
});

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  return {
    type: actionTypes.LOGOUT,
  };
};

export const selectLanguage = (selectedLanguage: string) => ({
  type: actionTypes.SELECT_LANGUAGE,
  selectedLanguage,
});
