import * as actionTypes from './actionTypes';
import {ReviewActionTypes} from './actionTypes';
import {ReviewsList} from '../../domain/Review';
import {HttpError} from '../../config/Axios/axios-instance';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type ReviewStateType = {
  reviews: ReviewsList | null;
  reviewsLoading: boolean;
  reviewsError: HttpError;
  reviewsFilter: ListParams;
};

export type ReviewActionType = ReviewStateType & {
  type: ReviewActionTypes;
};

export const initialState: ReviewStateType = {
  reviews: null,
  reviewsLoading: true,
  reviewsError: null,
  reviewsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'review.rating',
  },
};

const fetchReviewsStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewsLoading: true,
});

const fetchReviewsSuccess = (state: ReviewStateType, action: ReviewActionType): ReviewStateType => ({
  ...state,
  reviews: action.reviews,
  reviewsLoading: false,
  reviewsError: null,
});

const fetchReviewsFail = (state: ReviewStateType, action: ReviewActionType): ReviewStateType => ({
  ...state,
  reviewsError: action.reviewsError,
  reviewsLoading: false,
});

const setReviewsFilter = (state: ReviewStateType, action: ReviewActionType): ReviewStateType => ({
  ...state,
  reviewsFilter: action.reviewsFilter,
});

const reducer = (state = initialState, action: ReviewActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_REVIEWS_START:
    return fetchReviewsStart(state);
  case actionTypes.FETCH_REVIEWS_SUCCESS:
    return fetchReviewsSuccess(state, action);
  case actionTypes.FETCH_REVIEWS_FAIL:
    return fetchReviewsFail(state, action);
  case actionTypes.SET_REVIEWS_FILTER:
    return setReviewsFilter(state, action);
  default:
    return state;
  }
};

export default reducer;
