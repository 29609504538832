export const FETCH_TIPS_START = 'FETCH_TIPS_START';
export const FETCH_TIPS_SUCCESS = 'FETCH_TIPS_SUCCESS';
export const FETCH_TIPS_FAIL = 'FETCH_TIPS_FAIL';

export const FETCH_TIPS_FOR_EXPORT_START = 'FETCH_TIPS_FOR_EXPORT_START';
export const FETCH_TIPS_FOR_EXPORT_SUCCESS = 'FETCH_TIPS_FOR_EXPORT_SUCCESS';
export const FETCH_TIPS_FOR_EXPORT_FAIL = 'FETCH_TIPS_FOR_EXPORT_FAIL';

export const SET_TIPS_FILTER = 'SET_TIPS_FILTER';

export const CREATE_TIP_START = 'CREATE_TIP_START';
export const CREATE_TIP_SUCCESS = 'CREATE_TIP_SUCCESS';
export const CREATE_TIP_FAIL = 'CREATE_TIP_FAIL';

export type TipActionTypes =
  | typeof FETCH_TIPS_START
  | typeof FETCH_TIPS_SUCCESS
  | typeof FETCH_TIPS_FAIL
  | typeof FETCH_TIPS_FOR_EXPORT_START
  | typeof FETCH_TIPS_FOR_EXPORT_SUCCESS
  | typeof FETCH_TIPS_FOR_EXPORT_FAIL
  | typeof SET_TIPS_FILTER
  | typeof CREATE_TIP_START
  | typeof CREATE_TIP_SUCCESS
  | typeof CREATE_TIP_FAIL
  ;
