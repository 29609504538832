import React from 'react';
import Router from './config/Router/Router';
import StoreProvider from './config/StoreProvider/StoreProvider';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {ToastContainer} from 'react-toastify';

const App = () => (
  <GoogleOAuthProvider clientId="128614701449-f1e1jjgb2hbq404abira62a11dc8bp8r.apps.googleusercontent.com">
    <StoreProvider>
      <Router />
      <ToastContainer />
    </StoreProvider>
  </GoogleOAuthProvider>
);

export default App;
