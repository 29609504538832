import * as actionTypes from './actionTypes';
import {ContactActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';

export type ContactStateType = {
  sendContactLoading: boolean;
  sendContactError: HttpError;
  sendContactSuccess: boolean;
};

export type ContactActionType = ContactStateType & {
  type: ContactActionTypes;
};

export const initialState: ContactStateType = {
  sendContactLoading: false,
  sendContactError: null,
  sendContactSuccess: false,
};

const sendContactStart = (state: ContactStateType): ContactStateType => ({
  ...state,
  sendContactLoading: true,
});

const sendContactSuccess = (state: ContactStateType): ContactStateType => ({
  ...state,
  sendContactLoading: false,
  sendContactError: null,
  sendContactSuccess: true,
});

const sendContactFail = (state: ContactStateType, action: ContactActionType): ContactStateType => ({
  ...state,
  sendContactLoading: false,
  sendContactError: action.sendContactError,
});

const resetContactStore = (): ContactStateType => ({
  sendContactLoading: false,
  sendContactError: null,
  sendContactSuccess: false,
});

const reducer = (state = initialState, action: ContactActionType) => {
  switch (action.type) {
  case actionTypes.SEND_CONTACT_START:
    return sendContactStart(state);
  case actionTypes.SEND_CONTACT_SUCCESS:
    return sendContactSuccess(state);
  case actionTypes.SEND_CONTACT_FAIL:
    return sendContactFail(state, action);
  case actionTypes.RESET_CONTACT_STORE:
    return resetContactStore();
  default:
    return state;
  }
};

export default reducer;
