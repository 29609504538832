import * as actionTypes from './actionTypes';
import {AuthActionTypes} from './actionTypes';

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginGoogleLoading: boolean;
  loginGoogleError: string | null;
  selectedLanguage: string;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  selectedLanguage: localStorage.getItem('selectedLanguage') ?? 'lt',
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginGoogleFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const selectLanguage = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  localStorage.setItem('selectedLanguage', action.selectedLanguage);

  return {
    ...state,
    selectedLanguage: action.selectedLanguage,
  };
};

const logout = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  isInitCompleted: true,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
  case actionTypes.LOGIN_GOOGLE_START:
    return loginGoogleStart(state);
  case actionTypes.LOGIN_GOOGLE_SUCCESS:
    return loginGoogleSuccess(state);
  case actionTypes.LOGIN_GOOGLE_FAIL:
    return loginGoogleFail(state, action);
  case actionTypes.LOGOUT:
    return logout(state);
  case actionTypes.SELECT_LANGUAGE:
    return selectLanguage(state, action);
  default:
    return state;
  }
};

export default reducer;
