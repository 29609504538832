import React, {ReactNode, Suspense, useEffect} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {connect} from 'react-redux';
import Loader from '../../common/Loader/Loader';
import {StoreState} from '../StoreProvider/StoreProvider';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import * as languageService from '../../store/language/service';
import * as authService from '../../store/auth/service';
import {Language} from '../../domain/Language';
import {IntlProvider} from 'react-intl';
import {routes} from './routes';

const Login = React.lazy(() => import('../../pages/Public/Login/Login'));
const Home = React.lazy(() => import('../../pages/Public/Home/Home'));
const Restaurants = React.lazy(() => import('../../pages/Admin/Restaurants/Restaurants'));
const RestaurantCreate = React.lazy(() => import('../../pages/Admin/Restaurants/RestaurantCreate'));
const RestaurantEdit = React.lazy(() => import('../../pages/Admin/Restaurants/RestaurantEdit'));
const Users = React.lazy(() => import('../../pages/Admin/Users/Users'));
const UserCreate = React.lazy(() => import('../../pages/Admin/Users/UserCreate'));
const UserEdit = React.lazy(() => import('../../pages/Admin/Users/UserEdit'));
const Waiters = React.lazy(() => import('../../pages/Admin/Waiters/Waiters'));
const WaiterCreate = React.lazy(() => import('../../pages/Admin/Waiters/WaiterCreate'));
const WaiterEdit = React.lazy(() => import('../../pages/Admin/Waiters/WaiterEdit'));
const Reviews = React.lazy(() => import('../../pages/Admin/Reviews/Reviews'));
const Tip = React.lazy(() => import('../../pages/Public/Tip/Tip'));
const TipResponse = React.lazy(() => import('../../pages/Public/Tip/TipResponse'));
const Tips = React.lazy(() => import('../../pages/Admin/Tips/Tips'));
const PrivacyPolicy = React.lazy(() => import('../../pages/Public/PrivacyPolicy/PrivacyPolicy'));
const PurchasePolicy = React.lazy(() => import('../../pages/Public/PurchasePolicy/PurchasePolicy'));
const WaiterRegistration = React.lazy(() => import('../../pages/Public/WaiterRegistration/WaiterRegistration'));

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  languageLoading: boolean;
  onLanguageInit: (locale: string) => void;
  language: Language | null;
  selectedLanguage: string;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  onLanguageInit,
  language,
  languageLoading,
  selectedLanguage,
}: Props) => {
  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  useEffect(() => {
    onLanguageInit(selectedLanguage);
  }, [selectedLanguage]);

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const role = localStorage.getItem('role');

  const authenticatedRoutes = (role: string): ReactNode => {
    switch (role) {
    case 'ADMIN':
      return (
        <Routes>
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.admin} element={<Tips />} />
          <Route path={routes.restaurants} element={<Restaurants />} />
          <Route path={routes.restaurantCreate} element={<RestaurantCreate />} />
          <Route path={routes.restaurantEdit} element={<RestaurantEdit />} />
          <Route path={routes.users} element={<Users />} />
          <Route path={routes.userCreate} element={<UserCreate />} />
          <Route path={routes.userEdit} element={<UserEdit />} />
          <Route path={routes.waiters} element={<Waiters />} />
          <Route path={routes.waiterCreate} element={<WaiterCreate />} />
          <Route path={routes.waiterEdit} element={<WaiterEdit />} />
          <Route path={routes.reviews} element={<Reviews />} />
          <Route path={routes.tips} element={<Tips />} />
          <Route path={routes.tip} element={<Tip />} />
          <Route path={routes.tipResponse} element={<TipResponse />} />
          <Route path={routes.waiterRegistration} element={<WaiterRegistration />} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.purchasePolicy} element={<PurchasePolicy />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </Routes>
      );
    case 'OWNER':
      return (
        <Routes>
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.admin} element={<Reviews />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.tip} element={<Tip />} />
          <Route path={routes.reviews} element={<Reviews />} />
          <Route path={routes.tipResponse} element={<TipResponse />} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.purchasePolicy} element={<PurchasePolicy />} />
          <Route path={routes.waiterRegistration} element={<WaiterRegistration />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </Routes>
      );
    case 'WAITER':
      return (
        <Routes>
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.admin} element={<Tips />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.tip} element={<Tip />} />
          <Route path={routes.reviews} element={<Reviews />} />
          <Route path={routes.tips} element={<Tips />} />
          <Route path={routes.tipResponse} element={<TipResponse />} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.purchasePolicy} element={<PurchasePolicy />} />
          <Route path={routes.waiterRegistration} element={<WaiterRegistration />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </Routes>
      );
    default:
      return (
        <Routes>
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.tip} element={<Tip />} />
          <Route path={routes.tipResponse} element={<TipResponse />} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.purchasePolicy} element={<PurchasePolicy />} />
          <Route path={routes.waiterRegistration} element={<WaiterRegistration />} />
          <Route path="*" element={<Navigate to={routes.home} />} />
        </Routes>
      );
    }
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !languageLoading ? (
        <IntlProvider messages={mappedTranslations} locale={language?.locale ?? 'en'} defaultLocale="en">
          <Suspense fallback={<Loader isLoading isFullScreen />}>
            {isAuthenticated && role ? (
              authenticatedRoutes(role)
            ) : (
              <Routes>
                <Route path={routes.home} element={<Home />} />
                <Route path={routes.admin} element={<Tips />} />
                <Route path={routes.login} element={<Login />} />
                <Route path={routes.tip} element={<Tip />} />
                <Route path={routes.tipResponse} element={<TipResponse />} />
                <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
                <Route path={routes.purchasePolicy} element={<PurchasePolicy />} />
                <Route path={routes.waiterRegistration} element={<WaiterRegistration />} />
                <Route path="*" element={<Navigate to={routes.home} />} />
              </Routes>
            )}
          </Suspense>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  languageLoading: state.language.languageLoading,
  language: state.language.language,
  selectedLanguage: state.auth.selectedLanguage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onLanguageInit: (locale: string) => dispatch(languageService.fetchLanguage(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
