export const FETCH_RESTAURANTS_START = 'FETCH_RESTAURANTS_START';
export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';
export const FETCH_RESTAURANTS_FAIL = 'FETCH_RESTAURANTS_FAIL';

export const FETCH_RESTAURANT_START = 'FETCH_RESTAURANT_START';
export const FETCH_RESTAURANT_SUCCESS = 'FETCH_RESTAURANT_SUCCESS';
export const FETCH_RESTAURANT_FAIL = 'FETCH_RESTAURANT_FAIL';

export const SET_RESTAURANTS_FILTER = 'SET_RESTAURANTS_FILTER';

export const CREATE_RESTAURANT_START = 'CREATE_RESTAURANT_START';
export const CREATE_RESTAURANT_SUCCESS = 'CREATE_RESTAURANT_SUCCESS';
export const CREATE_RESTAURANT_FAIL = 'CREATE_RESTAURANT_FAIL';

export const UPDATE_RESTAURANT_START = 'UPDATE_RESTAURANT_START';
export const UPDATE_RESTAURANT_SUCCESS = 'UPDATE_RESTAURANT_SUCCESS';
export const UPDATE_RESTAURANT_FAIL = 'UPDATE_RESTAURANT_FAIL';

export const DELETE_RESTAURANT_START = 'DELETE_RESTAURANT_START';
export const DELETE_RESTAURANT_SUCCESS = 'DELETE_RESTAURANT_SUCCESS';
export const DELETE_RESTAURANT_FAIL = 'DELETE_RESTAURANT_FAIL';

export type RestaurantActionTypes =
  | typeof FETCH_RESTAURANTS_START
  | typeof FETCH_RESTAURANTS_SUCCESS
  | typeof FETCH_RESTAURANTS_FAIL
  | typeof SET_RESTAURANTS_FILTER
  | typeof FETCH_RESTAURANT_START
  | typeof FETCH_RESTAURANT_SUCCESS
  | typeof FETCH_RESTAURANT_FAIL
  | typeof CREATE_RESTAURANT_START
  | typeof CREATE_RESTAURANT_SUCCESS
  | typeof CREATE_RESTAURANT_FAIL
  | typeof UPDATE_RESTAURANT_START
  | typeof UPDATE_RESTAURANT_SUCCESS
  | typeof UPDATE_RESTAURANT_FAIL
  | typeof DELETE_RESTAURANT_START
  | typeof DELETE_RESTAURANT_SUCCESS
  | typeof DELETE_RESTAURANT_FAIL;
