import * as actionTypes from './actionTypes';
import {WaiterActionTypes} from './actionTypes';
import {Waiter, WaitersList} from '../../domain/Waiter';
import {HttpError} from '../../config/Axios/axios-instance';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type WaiterStateType = {
  waiters: WaitersList | null;
  waitersLoading: boolean;
  waitersError: HttpError;
  waitersFilter: ListParams;
  waiter: Waiter | null;
  waiterLoading: boolean;
  waiterError: HttpError;
  publicWaiter: Waiter | null;
  publicWaiterLoading: boolean;
  publicWaiterError: HttpError;
  waiterCreateLoading: boolean;
  waiterCreateError: HttpError;
  waiterCreateSuccess: boolean;
  createdWaiter: Waiter | null;
  waiterUpdateLoading: boolean;
  waiterUpdateError: HttpError;
  waiterUpdateSuccess: boolean;
  waitersListUpdateNeeded: boolean;
  waiterDeleteLoading: boolean;
  waiterDeleteError: HttpError;
};

export type WaiterActionType = WaiterStateType & {
  type: WaiterActionTypes;
};

export const initialState: WaiterStateType = {
  waiters: null,
  waitersLoading: true,
  waitersError: null,
  waitersFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'waiter.name',
  },
  waiter: null,
  waiterLoading: false,
  waiterError: null,
  publicWaiter: null,
  publicWaiterLoading: false,
  publicWaiterError: null,
  waiterCreateLoading: false,
  waiterCreateError: null,
  waiterCreateSuccess: false,
  createdWaiter: null,
  waiterUpdateLoading: false,
  waiterUpdateError: null,
  waiterUpdateSuccess: false,
  waiterDeleteLoading: false,
  waiterDeleteError: null,
  waitersListUpdateNeeded: false,
};

const fetchWaitersStart = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  waitersLoading: true,
  waiterCreateSuccess: false,
  waiterUpdateSuccess: false,
});

const fetchWaitersSuccess = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waiters: action.waiters,
  waitersLoading: false,
  waitersError: null,
  waitersListUpdateNeeded: false,
});

const fetchWaitersFail = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waitersError: action.waitersError,
  waitersLoading: false,
});

const setWaitersFilter = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waitersFilter: action.waitersFilter,
});

const fetchWaiterStart = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  waiterLoading: true,
  waiterUpdateSuccess: false,
  waiterCreateSuccess: false,
  waiterCreateError: null,
  waiterUpdateError: null,
});

const fetchWaiterSuccess = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waiter: action.waiter,
  waiterLoading: false,
  waiterError: null,
});

const fetchWaiterFail = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waiterError: action.waiterError,
  waiterLoading: false,
});

const fetchPublicWaiterStart = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  publicWaiterLoading: true,
});

const fetchPublicWaiterSuccess = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  publicWaiter: action.publicWaiter,
  publicWaiterLoading: false,
  publicWaiterError: null,
});

const fetchPublicWaiterFail = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  publicWaiterError: action.publicWaiterError,
  publicWaiterLoading: false,
});

const createWaiterStart = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  waiterCreateLoading: true,
});

const createWaiterSuccess = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waiterCreateLoading: false,
  waiterCreateSuccess: true,
  waiterCreateError: null,
  createdWaiter: action.createdWaiter,
});

const createWaiterFail = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waiterCreateLoading: false,
  waiterCreateError: action.waiterCreateError,
});

const updateWaiterStart = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  waiterUpdateLoading: true,
});

const updateWaiterSuccess = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  waiterUpdateLoading: false,
  waiterUpdateSuccess: true,
  waiterUpdateError: null,
});

const updateWaiterFail = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waiterUpdateLoading: false,
  waiterUpdateError: action.waiterUpdateError,
});

const deleteWaiterStart = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  waiterDeleteLoading: true,
});

const deleteWaiterSuccess = (state: WaiterStateType): WaiterStateType => ({
  ...state,
  waiterDeleteLoading: false,
  waiterDeleteError: null,
  waitersListUpdateNeeded: true,
});

const deleteWaiterFail = (state: WaiterStateType, action: WaiterActionType): WaiterStateType => ({
  ...state,
  waiterDeleteLoading: false,
  waiterDeleteError: action.waiterDeleteError,
});

const reducer = (state = initialState, action: WaiterActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_WAITERS_START:
    return fetchWaitersStart(state);
  case actionTypes.FETCH_WAITERS_SUCCESS:
    return fetchWaitersSuccess(state, action);
  case actionTypes.FETCH_WAITERS_FAIL:
    return fetchWaitersFail(state, action);
  case actionTypes.SET_WAITERS_FILTER:
    return setWaitersFilter(state, action);
  case actionTypes.FETCH_WAITER_START:
    return fetchWaiterStart(state);
  case actionTypes.FETCH_WAITER_SUCCESS:
    return fetchWaiterSuccess(state, action);
  case actionTypes.FETCH_WAITER_FAIL:
    return fetchWaiterFail(state, action);
  case actionTypes.FETCH_PUBLIC_WAITER_START:
    return fetchPublicWaiterStart(state);
  case actionTypes.FETCH_PUBLIC_WAITER_SUCCESS:
    return fetchPublicWaiterSuccess(state, action);
  case actionTypes.FETCH_PUBLIC_WAITER_FAIL:
    return fetchPublicWaiterFail(state, action);
  case actionTypes.CREATE_WAITER_START:
    return createWaiterStart(state);
  case actionTypes.CREATE_WAITER_SUCCESS:
    return createWaiterSuccess(state, action);
  case actionTypes.CREATE_WAITER_FAIL:
    return createWaiterFail(state, action);
  case actionTypes.UPDATE_WAITER_START:
    return updateWaiterStart(state);
  case actionTypes.UPDATE_WAITER_SUCCESS:
    return updateWaiterSuccess(state);
  case actionTypes.UPDATE_WAITER_FAIL:
    return updateWaiterFail(state, action);
  case actionTypes.DELETE_WAITER_START:
    return deleteWaiterStart(state);
  case actionTypes.DELETE_WAITER_SUCCESS:
    return deleteWaiterSuccess(state);
  case actionTypes.DELETE_WAITER_FAIL:
    return deleteWaiterFail(state, action);
  default:
    return state;
  }
};

export default reducer;
