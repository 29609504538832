export const FETCH_WAITERS_START = 'FETCH_WAITERS_START';
export const FETCH_WAITERS_SUCCESS = 'FETCH_WAITERS_SUCCESS';
export const FETCH_WAITERS_FAIL = 'FETCH_WAITERS_FAIL';

export const FETCH_WAITER_START = 'FETCH_WAITER_START';
export const FETCH_WAITER_SUCCESS = 'FETCH_WAITER_SUCCESS';
export const FETCH_WAITER_FAIL = 'FETCH_WAITER_FAIL';

export const FETCH_PUBLIC_WAITER_START = 'FETCH_PUBLIC_WAITER_START';
export const FETCH_PUBLIC_WAITER_SUCCESS = 'FETCH_PUBLIC_WAITER_SUCCESS';
export const FETCH_PUBLIC_WAITER_FAIL = 'FETCH_PUBLIC_WAITER_FAIL';

export const SET_WAITERS_FILTER = 'SET_WAITERS_FILTER';

export const CREATE_WAITER_START = 'CREATE_WAITER_START';
export const CREATE_WAITER_SUCCESS = 'CREATE_WAITER_SUCCESS';
export const CREATE_WAITER_FAIL = 'CREATE_WAITER_FAIL';

export const UPDATE_WAITER_START = 'UPDATE_WAITER_START';
export const UPDATE_WAITER_SUCCESS = 'UPDATE_WAITER_SUCCESS';
export const UPDATE_WAITER_FAIL = 'UPDATE_WAITER_FAIL';

export const DELETE_WAITER_START = 'DELETE_WAITER_START';
export const DELETE_WAITER_SUCCESS = 'DELETE_WAITER_SUCCESS';
export const DELETE_WAITER_FAIL = 'DELETE_WAITER_FAIL';

export type WaiterActionTypes =
  | typeof FETCH_WAITERS_START
  | typeof FETCH_WAITERS_SUCCESS
  | typeof FETCH_WAITERS_FAIL
  | typeof SET_WAITERS_FILTER
  | typeof FETCH_WAITER_START
  | typeof FETCH_WAITER_SUCCESS
  | typeof FETCH_WAITER_FAIL
  | typeof FETCH_PUBLIC_WAITER_START
  | typeof FETCH_PUBLIC_WAITER_SUCCESS
  | typeof FETCH_PUBLIC_WAITER_FAIL
  | typeof CREATE_WAITER_START
  | typeof CREATE_WAITER_SUCCESS
  | typeof CREATE_WAITER_FAIL
  | typeof UPDATE_WAITER_START
  | typeof UPDATE_WAITER_SUCCESS
  | typeof UPDATE_WAITER_FAIL
  | typeof DELETE_WAITER_START
  | typeof DELETE_WAITER_SUCCESS
  | typeof DELETE_WAITER_FAIL;
