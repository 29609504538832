export const LOGIN_GOOGLE_START = 'LOGIN_GOOGLE_START';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';

export const LOGOUT = 'LOGOUT';

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

export type AuthActionTypes =
  typeof LOGIN_GOOGLE_START |
  typeof LOGIN_GOOGLE_SUCCESS |
  typeof LOGIN_GOOGLE_FAIL |
  typeof LOGOUT |
  typeof SELECT_LANGUAGE
;
