import * as actionTypes from './actionTypes';
import {UserActionTypes} from './actionTypes';
import {User, UsersList} from '../../domain/User';
import {HttpError} from '../../config/Axios/axios-instance';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type UserStateType = {
  user: User | null;
  userLoading: boolean;
  userError: HttpError;
  users: UsersList | null;
  usersLoading: boolean;
  usersError: HttpError;
  usersFilter: ListParams;
  userCreateLoading: boolean;
  userCreateError: HttpError;
  userCreateSuccess: boolean;
  userUpdateLoading: boolean;
  userUpdateError: HttpError;
  userUpdateSuccess: boolean;
  usersListUpdateNeeded: boolean;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
};

export const initialState: UserStateType = {
  user: null,
  userLoading: false,
  userError: null,
  users: null,
  usersLoading: true,
  usersError: null,
  usersFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'user.email',
  },
  userCreateError: null,
  userCreateLoading: false,
  userCreateSuccess: false,
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  usersListUpdateNeeded: false,
};

const fetchUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userLoading: true,
});

const fetchUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
});

const fetchUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
  userUpdateSuccess: false,
  userCreateSuccess: false,
});

const fetchUsersSuccess = (state: UserStateType, action: UserActionType): UserStateType => ({
  ...state,
  users: action.users,
  usersLoading: false,
  usersError: null,
});

const fetchUsersFail = (state: UserStateType, action: UserActionType): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const createUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
});

const createUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: null,
  userCreateSuccess: true,
  usersListUpdateNeeded: true,
});

const createUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: action.userCreateError,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: null,
  userUpdateSuccess: true,
  usersListUpdateNeeded: true,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: action.userUpdateError,
});

const setUsersFilter = (state: UserStateType, action: UserActionType): UserStateType => ({
  ...state,
  usersFilter: action.usersFilter,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_USER_START:
    return fetchUserStart(state);
  case actionTypes.FETCH_USER_SUCCESS:
    return fetchUserSuccess(state, action);
  case actionTypes.FETCH_USER_FAIL:
    return fetchUserFail(state, action);
  case actionTypes.FETCH_USERS_START:
    return fetchUsersStart(state);
  case actionTypes.FETCH_USERS_SUCCESS:
    return fetchUsersSuccess(state, action);
  case actionTypes.FETCH_USERS_FAIL:
    return fetchUsersFail(state, action);
  case actionTypes.CREATE_USER_START:
    return createUserStart(state);
  case actionTypes.CREATE_USER_SUCCESS:
    return createUserSuccess(state);
  case actionTypes.CREATE_USER_FAIL:
    return createUserFail(state, action);
  case actionTypes.UPDATE_USER_START:
    return updateUserStart(state);
  case actionTypes.UPDATE_USER_SUCCESS:
    return updateUserSuccess(state);
  case actionTypes.UPDATE_USER_FAIL:
    return updateUserFail(state, action);
  case actionTypes.SET_USERS_FILTER:
    return setUsersFilter(state, action);
  default:
    return state;
  }
};

export default reducer;
