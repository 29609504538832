export const FETCH_REVIEWS_START = 'FETCH_REVIEWS_START';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAIL = 'FETCH_REVIEWS_FAIL';

export const SET_REVIEWS_FILTER = 'SET_REVIEWS_FILTER';

export type ReviewActionTypes =
  | typeof FETCH_REVIEWS_START
  | typeof FETCH_REVIEWS_SUCCESS
  | typeof FETCH_REVIEWS_FAIL
  | typeof SET_REVIEWS_FILTER
  ;
