import axios from '../../config/Axios/axios-instance';
import {
  loginGoogleFail,
  loginGoogleStart,
  loginGoogleSuccess,
  logout,
} from './actions';
import {Dispatch} from 'redux';

const API_AUTH_URL = '/auth';

export type LoginGoogleRequest = {
  email: string,
  firstName?: string,
  lastName?: string,
  imageUrl?: string,
  token: string,
};

export const loginWithGoogle =
  (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
    dispatch(loginGoogleStart());
    return axios.post(`${API_AUTH_URL}/google`, {
      ...inputs,
    })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(loginGoogleSuccess());
      })
      .catch((err) => {
        dispatch(loginGoogleFail(err.response.data.message));
      })
    ;
  };

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(loginGoogleSuccess()) : dispatch(logout());
};

export const logoutUser = () => (dispatch: Dispatch) => {
  dispatch(logout());
};
