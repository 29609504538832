import * as actionTypes from './actionTypes';
import {TipActionTypes} from './actionTypes';
import {TipsList} from '../../domain/Tip';
import {HttpError} from '../../config/Axios/axios-instance';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type TipStateType = {
  tips: TipsList | null;
  tipsLoading: boolean;
  tipsError: HttpError;
  tipsForExport: TipsList | null;
  tipsForExportLoading: boolean;
  tipsForExportError: HttpError;
  tipsFilter: ListParams;
  tipCreateLoading: boolean;
  tipCreateError: HttpError;
  tipCreateSuccess: boolean;
  createdTip: string | null;
};

export type TipActionType = TipStateType & {
  type: TipActionTypes;
};

export const initialState: TipStateType = {
  tips: null,
  tipsLoading: true,
  tipsError: null,
  tipsForExport: null,
  tipsForExportLoading: false,
  tipsForExportError: null,
  tipsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'tip.transactionNumber',
    sortDirection: 'DESC',
  },
  tipCreateLoading: false,
  tipCreateError: null,
  tipCreateSuccess: false,
  createdTip: null,
};

const fetchTipsStart = (state: TipStateType): TipStateType => ({
  ...state,
  tipsLoading: true,
  tipCreateSuccess: false,
});

const fetchTipsSuccess = (state: TipStateType, action: TipActionType): TipStateType => ({
  ...state,
  tips: action.tips,
  tipsLoading: false,
  tipsError: null,
});

const fetchTipsFail = (state: TipStateType, action: TipActionType): TipStateType => ({
  ...state,
  tipsError: action.tipsError,
  tipsLoading: false,
});

const fetchTipsForExportStart = (state: TipStateType): TipStateType => ({
  ...state,
  tipsForExportLoading: true,
  tipsForExport: null,
});

const fetchTipsForExportSuccess = (state: TipStateType, action: TipActionType): TipStateType => ({
  ...state,
  tipsForExport: action.tipsForExport,
  tipsForExportLoading: false,
  tipsForExportError: null,
});

const fetchTipsForExportFail = (state: TipStateType, action: TipActionType): TipStateType => ({
  ...state,
  tipsForExportError: action.tipsForExportError,
  tipsForExportLoading: false,
});

const setTipsFilter = (state: TipStateType, action: TipActionType): TipStateType => ({
  ...state,
  tipsFilter: action.tipsFilter,
});

const createTipStart = (state: TipStateType): TipStateType => ({
  ...state,
  tipCreateLoading: true,
});

const createTipSuccess = (state: TipStateType, action: TipActionType): TipStateType => ({
  ...state,
  tipCreateLoading: false,
  tipCreateSuccess: true,
  tipCreateError: null,
  createdTip: action.createdTip,
});

const createTipFail = (state: TipStateType, action: TipActionType): TipStateType => ({
  ...state,
  tipCreateLoading: false,
  tipCreateError: action.tipCreateError,
});

const reducer = (state = initialState, action: TipActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_TIPS_START:
    return fetchTipsStart(state);
  case actionTypes.FETCH_TIPS_SUCCESS:
    return fetchTipsSuccess(state, action);
  case actionTypes.FETCH_TIPS_FAIL:
    return fetchTipsFail(state, action);
  case actionTypes.FETCH_TIPS_FOR_EXPORT_START:
    return fetchTipsForExportStart(state);
  case actionTypes.FETCH_TIPS_FOR_EXPORT_SUCCESS:
    return fetchTipsForExportSuccess(state, action);
  case actionTypes.FETCH_TIPS_FOR_EXPORT_FAIL:
    return fetchTipsForExportFail(state, action);
  case actionTypes.SET_TIPS_FILTER:
    return setTipsFilter(state, action);
  case actionTypes.CREATE_TIP_START:
    return createTipStart(state);
  case actionTypes.CREATE_TIP_SUCCESS:
    return createTipSuccess(state, action);
  case actionTypes.CREATE_TIP_FAIL:
    return createTipFail(state, action);
  default:
    return state;
  }
};

export default reducer;
