import * as actionTypes from './actionTypes';
import {RestaurantActionTypes} from './actionTypes';
import {Restaurant, RestaurantsList} from '../../domain/Restaurant';
import {HttpError} from '../../config/Axios/axios-instance';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type RestaurantStateType = {
  restaurants: RestaurantsList | null;
  restaurantsLoading: boolean;
  restaurantsError: HttpError;
  restaurantsFilter: ListParams;
  restaurant: Restaurant | null;
  restaurantLoading: boolean;
  restaurantError: HttpError;
  restaurantCreateLoading: boolean;
  restaurantCreateError: HttpError;
  restaurantCreateSuccess: boolean;
  createdRestaurant: Restaurant | null;
  restaurantUpdateLoading: boolean;
  restaurantUpdateError: HttpError;
  restaurantUpdateSuccess: boolean;
  restaurantDeleteLoading: boolean;
  restaurantDeleteError: HttpError;
  restaurantsListUpdateNeeded: boolean;
};

export type RestaurantActionType = RestaurantStateType & {
  type: RestaurantActionTypes;
};

export const initialState: RestaurantStateType = {
  restaurants: null,
  restaurantsLoading: true,
  restaurantsError: null,
  restaurantsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'restaurant.name',
  },
  restaurant: null,
  restaurantLoading: false,
  restaurantError: null,
  restaurantCreateLoading: false,
  restaurantCreateError: null,
  restaurantCreateSuccess: false,
  createdRestaurant: null,
  restaurantUpdateLoading: false,
  restaurantUpdateError: null,
  restaurantUpdateSuccess: false,
  restaurantDeleteLoading: false,
  restaurantDeleteError: null,
  restaurantsListUpdateNeeded: false,
};

const fetchRestaurantsStart = (state: RestaurantStateType): RestaurantStateType => ({
  ...state,
  restaurantsLoading: true,
  restaurantCreateSuccess: false,
  restaurantUpdateSuccess: false,
});

const fetchRestaurantsSuccess = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurants: action.restaurants,
  restaurantsLoading: false,
  restaurantsError: null,
  restaurantsListUpdateNeeded: false,
});

const fetchRestaurantsFail = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurantsError: action.restaurantsError,
  restaurantsLoading: false,
});

const setRestaurantsFilter = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurantsFilter: action.restaurantsFilter,
});

const fetchRestaurantStart = (state: RestaurantStateType): RestaurantStateType => ({
  ...state,
  restaurantLoading: true,
  restaurantUpdateSuccess: false,
  restaurantCreateSuccess: false,
  restaurantCreateError: null,
  restaurantUpdateError: null,
});

const fetchRestaurantSuccess = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurant: action.restaurant,
  restaurantLoading: false,
  restaurantError: null,
});

const fetchRestaurantFail = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurantError: action.restaurantError,
  restaurantLoading: false,
});

const createRestaurantStart = (state: RestaurantStateType): RestaurantStateType => ({
  ...state,
  restaurantCreateLoading: true,
});

const createRestaurantSuccess = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurantCreateLoading: false,
  restaurantCreateSuccess: true,
  restaurantCreateError: null,
  createdRestaurant: action.createdRestaurant,
});

const createRestaurantFail = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurantCreateLoading: false,
  restaurantCreateError: action.restaurantCreateError,
});

const updateRestaurantStart = (state: RestaurantStateType): RestaurantStateType => ({
  ...state,
  restaurantUpdateLoading: true,
});

const updateRestaurantSuccess = (state: RestaurantStateType): RestaurantStateType => ({
  ...state,
  restaurantUpdateLoading: false,
  restaurantUpdateSuccess: true,
  restaurantUpdateError: null,
});

const updateRestaurantFail = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurantUpdateLoading: false,
  restaurantUpdateError: action.restaurantUpdateError,
});

const deleteRestaurantStart = (state: RestaurantStateType): RestaurantStateType => ({
  ...state,
  restaurantDeleteLoading: true,
});

const deleteRestaurantSuccess = (state: RestaurantStateType): RestaurantStateType => ({
  ...state,
  restaurantDeleteLoading: false,
  restaurantDeleteError: null,
  restaurantsListUpdateNeeded: true,
});

const deleteRestaurantFail = (state: RestaurantStateType, action: RestaurantActionType): RestaurantStateType => ({
  ...state,
  restaurantDeleteLoading: false,
  restaurantDeleteError: action.restaurantDeleteError,
});

const reducer = (state = initialState, action: RestaurantActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_RESTAURANTS_START:
    return fetchRestaurantsStart(state);
  case actionTypes.FETCH_RESTAURANTS_SUCCESS:
    return fetchRestaurantsSuccess(state, action);
  case actionTypes.FETCH_RESTAURANTS_FAIL:
    return fetchRestaurantsFail(state, action);
  case actionTypes.SET_RESTAURANTS_FILTER:
    return setRestaurantsFilter(state, action);
  case actionTypes.FETCH_RESTAURANT_START:
    return fetchRestaurantStart(state);
  case actionTypes.FETCH_RESTAURANT_SUCCESS:
    return fetchRestaurantSuccess(state, action);
  case actionTypes.FETCH_RESTAURANT_FAIL:
    return fetchRestaurantFail(state, action);
  case actionTypes.CREATE_RESTAURANT_START:
    return createRestaurantStart(state);
  case actionTypes.CREATE_RESTAURANT_SUCCESS:
    return createRestaurantSuccess(state, action);
  case actionTypes.CREATE_RESTAURANT_FAIL:
    return createRestaurantFail(state, action);
  case actionTypes.UPDATE_RESTAURANT_START:
    return updateRestaurantStart(state);
  case actionTypes.UPDATE_RESTAURANT_SUCCESS:
    return updateRestaurantSuccess(state);
  case actionTypes.UPDATE_RESTAURANT_FAIL:
    return updateRestaurantFail(state, action);
  case actionTypes.DELETE_RESTAURANT_START:
    return deleteRestaurantStart(state);
  case actionTypes.DELETE_RESTAURANT_SUCCESS:
    return deleteRestaurantSuccess(state);
  case actionTypes.DELETE_RESTAURANT_FAIL:
    return deleteRestaurantFail(state, action);
  default:
    return state;
  }
};

export default reducer;
