export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const SET_USERS_FILTER = 'SET_USERS_FILTER';

export type UserActionTypes =
  | typeof FETCH_USER_START
  | typeof FETCH_USER_SUCCESS
  | typeof FETCH_USER_FAIL
  | typeof FETCH_USERS_START
  | typeof FETCH_USERS_SUCCESS
  | typeof FETCH_USERS_FAIL
  | typeof CREATE_USER_START
  | typeof CREATE_USER_SUCCESS
  | typeof CREATE_USER_FAIL
  | typeof UPDATE_USER_START
  | typeof UPDATE_USER_SUCCESS
  | typeof UPDATE_USER_FAIL
  | typeof SET_USERS_FILTER;
