import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore, Store} from 'redux';
import thunk from 'redux-thunk';
import authReducer, {AuthStateType} from '../../store/auth/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import userReducer, {UserStateType} from '../../store/user/reducer';
import languageReducer, {LanguageStateType} from '../../store/language/reducer';
import restaurantReducer, {RestaurantStateType} from '../../store/restaurant/reducer';
import waiterReducer, {WaiterStateType} from '../../store/waiter/reducer';
import tipReducer, {TipStateType} from '../../store/tip/reducer';
import contactReducer, {ContactStateType} from '../../store/contact/reducer';
import reviewReducer, {ReviewStateType} from '../../store/review/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  language: LanguageStateType;
  restaurant: RestaurantStateType;
  waiter: WaiterStateType;
  tip: TipStateType;
  contact: ContactStateType;
  review: ReviewStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  language: languageReducer,
  waiter: waiterReducer,
  restaurant: restaurantReducer,
  tip: tipReducer,
  contact: contactReducer,
  review: reviewReducer,
});

export const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const {dispatch} = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.response.config.url !== '/token') {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({children}: Props) => <Provider store={store}>{children}</Provider>;

export default StoreProvider;
