export const SEND_CONTACT_START = 'SEND_CONTACT_START';
export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_FAIL = 'SEND_CONTACT_FAIL';
export const RESET_CONTACT_STORE = 'RESET_CONTACT_STORE';

export type ContactActionTypes =
  | typeof SEND_CONTACT_START
  | typeof SEND_CONTACT_SUCCESS
  | typeof SEND_CONTACT_FAIL
  | typeof RESET_CONTACT_STORE;
