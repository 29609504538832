export const routes = {
  home: '/',
  login: '/login',
  admin: '/admin',
  restaurants: '/admin/restaurants',
  restaurantCreate: '/admin/restaurants/create-new-restaurant',
  restaurantEdit: '/admin/restaurants/:id/edit',
  users: '/admin/users',
  userCreate: '/admin/waiters/create-new-user',
  userEdit: '/admin/users/:id/edit',
  waiters: '/admin/waiters',
  waiterCreate: '/admin/waiters/create-new-waiter',
  waiterEdit: '/admin/waiters/:id/edit',
  reviews: '/admin/reviews',
  tip: '/tips/:serialNumber',
  tipResponse: '/tips/:serialNumber/response',
  tips: '/admin/tips',
  privacyPolicy: '/privacy-policy',
  purchasePolicy: '/purchase-policy',
  waiterRegistration: '/activation',
};
